import React, { useState, useEffect } from 'react';
import BackButton from './BackButton';
import '../App.css';
import SEO from './SEO';
import ArrowBack from './ArrowBack';

//youtube :(
// const films = [
//   { id: 1001, title: "Зов", imageName: "zov", videoID: "6CXyC-F2nY0", description:"Что сердце бьется\r\n- Ничего не значит,\r\nОно молчит,\r\nЗадавленное суетой и бытом,\r\nВначале нужно оживить его!!!" },
//   { id: 1002, title: "Дольмены счастья", imageName: "dolmen", videoID: "StJZE2I8zhs", description: "" },
//   { id: 1003, title: "Чаша Бога", imageName: "god", videoID: "Gu-fUWYgB14", description: "" },
//   { id: 1004, title: "Путь к Ра", imageName: "ra", videoID: "0x7ThGYaT_I", description: "" },
//   { id: 1005, title: "Бессмертие", imageName: "inf", videoID: "DrLrdk8d-7g", description: "" },
//   { id: 1006, title: "Вдохновение сердца. Часть I. Осознание", imageName: "heart_1", videoID: "P0tbuUb4oFE", description: "" },
//   { id: 1007, title: "Вдохновение сердца. Часть II. Проникновение", imageName: "heart_2", videoID: "-9CIXvcrzIw", description: "" },
//   { id: 1008, title: "Вдохновение Сердца. Часть III. Озарение", imageName: "heart_3", videoID: "FqdUk2qIe_g", description: "" },
//   { id: 1008, title: "Продолжение, которое появится с вашей помощью", imageName: "heart_3", videoID: "FqdUk2qIe_g", description: "Помочь съёмкам:\r\nСбер 5469 3801 4381 8747" }
// ];

const films = [
  { id: 1001, title: "Зов", imageName: "zov", videoID: "889b33f9f0c2d0d4e9f2112fe500d33f", description:"Что сердце бьется\r\n- Ничего не значит,\r\nОно молчит,\r\nЗадавленное суетой и бытом,\r\nВначале нужно оживить его!!!" },
  { id: 1002, title: "Дольмены", imageName: "dolmen", videoID: "31712f478f75b989378fa9dfd1ee3402", description: "Дольмены - место силы, знанья,\r\nЛюбви Планеты этой,\r\nСозвучие Вселенной и гармонии,\r\nСознание Единства творения.\r\nИз множества частиц,\r\nИз чистоты,\r\nСливаются потоки,\r\nГде нет конца,\r\nНет времени,\r\nНет ни добра, ни зла\r\nЕсть - Благо\r\nИ Бесконечность" },
  { id: 1003, title: "Чаша Бога", imageName: "god", videoID: "68b4ec896e220c1699e24128dc8ef91a", description: "Да будет вечен\r\nКаждый вздох и взгляд,\r\nДа будет свет\r\nДорогой в беспредельность." },
  { id: 1004, title: "Путь к Ра", imageName: "ra", videoID: "3db3cc568346d4dddfef60fa56a38701", description: "Всем Идущим, Ищущим,\r\nУстремлённым,\r\n принявшим на себя ответственность\r\n за судьбы всего Человечества\r\n - посвящается!" },
  { id: 1005, title: "Бессмертие", imageName: "inf", videoID: "afcdd2c75891821178f3a9a0b07d28ce", description: "Информация, воспринятая умом,\r\nНе гарантирует Знания.\r\nИбо Знание есть свет,\r\nРаспознаваемый сердцем,\r\nИ реализованный в делах и поступках\r\nОбъективной реальности" }, 
  { id: 1006, title: "Вдохновение Сердца. Часть I Осознание", imageName: "heart_1", videoID: "0dc1430c16e94491f5a3ca8487a32600", description: "Удивительна земля Русская,\r\nСколько сил богатырских \r\nв себе хранит,\r\nБлагословенна Богом,\r\nНожками святых истоптана.\r\nГде силушка из земли бьёт,\r\nТам нет места нечисти.\r\nПусть увидят невидящие,\r\nПусть услышат неслышащие,\r\nПусть почувствуют и уверуют \r\nневерующие.\r\nВремя пришло." },
  { id: 1007, title: "Вдохновение Сердца. Часть II Проникновение", imageName: "heart_2", videoID: "cc37ee794fbe58a46c68f2d99ee33cf4", description: "Когда вы творите сердцем,\r\nВсё, что вмещается в него\r\n– преображается,\r\nЗатем всё, преображенное вами\r\n–преображает вас.\r\nВсё, о чём вы думаете сейчас\r\nили о чём мечтаете, начинает быть\r\nи обязательно происходит,\r\nизменяя вас навсегда" },
  { id: 1008, title: "Вдохновение Сердца. Часть III Озарение", imageName: "heart_3", videoID: "9f06666f08d4e8c0bbe5d2be4a33abd2", description: "Зачем родился я?\r\nЗачем живу?\r\nИ у кого о том спросить совета,\r\nкогда вокруг кружат одни сомнения,\r\nВопрос был задан\r\nИ вот оно, предчувствие ответа,\r\n–Озарение." },
  { id: 1009, title: "9-ый фильм", imageName: "heart_4", videoID: "", description: "Здесь пока ничего нет,\r\nно общими усилиями\r\nновый фильм появится\r\n\r\nПоддержать проект:\r\nСбер 5469 3801 4381 8747" },
];
const FilmsPage = () => {
  const [selectedFilm, setSelectedFilm] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const iframe = document.querySelector('.modal-content iframe');

      if (iframe) { 
        if (viewportWidth < 480) {
          iframe.style.width = "100%";
          iframe.style.height = "250px";
        } else if (viewportWidth < 640) {
          iframe.style.width = "100%";
          iframe.style.height = "300px";
        } else {
          iframe.style.width = "560px";
          iframe.style.height = "315px";
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFilmClick = (film) => {
    setSelectedFilm(film);
  };

  const handleCloseModal = () => {
    setSelectedFilm(null);
  };

  return (
    <div className="content">
      <ArrowBack />
      <SEO
        title="Фильмы самопознания, философские фильмы, фильмы со смыслом" 
        description="Фильмы Как путь по звёздам отыскать, режиссёр Олег Рудюк" 
        keywords="Фильмы самопознания, философские фильмы, фильмы со смыслом, Иерархия, Учителя, Живая Этика, Агни Йога, Познай себя" 
      />
      <h1>Серия фильмов<br /> "Как путь по звёздам отыскать"</h1>
      <h2>Режиссёр: Олег Рудюк</h2>
      <p>Фильмы серии – действенный путь развития и пробуждения Души к вечным целям, ориентирам и ценностям. </p>
      <p>Это не просто фильмы, а духовный инструмент самопознания. Для многократного просмотра и постижения своего сердца и связи со всей Вселенной</p>
      <div className="film-list">
        {films.map(film => (
          <div key={film.id} className="film" onClick={() => handleFilmClick(film)}>
            <img src={`./img/${film.imageName}.jpeg`} alt={film.title} />
            <p>{film.title}</p>   
          </div>
        ))}
      </div>
      {selectedFilm && (
        <div id="myModal" className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleCloseModal}>&times;</span>
            {/* <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${selectedFilm.videoID}?autoplay=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={selectedFilm.title}
            ></iframe> */}
              <iframe
                width="560"
                height="315"
                src={`https://rutube.ru/play/embed/${selectedFilm.videoID}`}
                frameBorder="0" 
                allow="clipboard-write; autoplay" 
                webkitAllowFullScreen 
                mozallowfullscreen 
                allowFullScreen
                title={selectedFilm.title}
            ></iframe>
            <h2>{selectedFilm.title}</h2>
           
          {selectedFilm.description.split('\n').map((line, index) => (
                <span key={index}>{line}<br /></span>))}
          </div>
        </div>
      )}
      <BackButton />
    </div>
  );
};

export default FilmsPage;